import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../common";

const initialState = {
  userOrg: null,
  orgs: {},
  orgList: null,
  organisers: {},
  notificationTypes: [],
  continuerResponders: [],
  investigatorResponders: [],
}

const orgSlice = createSlice({
  name: "org",
  initialState: initialState,
  reducers: {
    getUserOrg: () => { },
    fetchOrgMappings: () => { },
    fetchAcceptedOrgMappings: () => { },
    fetchPendingOrgMappings: () => { },
    activateDeactivateOrgMapping: () => { },
    isOrgUserExist: () => { },
    fetchIndustryList: () => { },
    fetchCountryList: () => { },
    fetchDepartments: () => { },
    fetchDesignations: () => { },
    fetchOrgById: () => { },
    fetchOrgs: () => { },
    fetchOrganisers: () => { },
    updateOrganiser: () => { },
    enableOrDisableOrg: () => { },
    fetchOrgAsmtModels: () => { },
    fetchOrgSSO: () => { },
    updateOrgSSO: () => { },
    fetchNotifyFreqList: () => { },
    setModelStatus: (state) => { },
    setOrgConfig: () => { },
    createNewOrg: () => { },
    fetchContinuerRespondersList: () => { },
    fetchInvestigatorRespondersList: () => { },
    creatOrUpdateOrg: (state) => {
      state.createOrgSuccess = false
      state.updateOrgSuccess = false
    },
    getUserOrgSuccess: (state, actions) => {
      state.userOrg = actions.payload;
    },
    fetchPendingOrgsSuccess: (state, actions) => {
      state.pendingOrgs = actions.payload;
    },
    getAcceptedOrgsSuccess: (state, actions) => {
      state.acceptedOrgs = actions.payload;
    },
    isOrgUserExistSuccess: (state, actions) => {
      state.isOrgUserExist = actions.payload;
    },
    fetchIndustryListSuccess: (state, action) => {
      state.industryList = action.payload;
    },
    fetchCountryListSuccess: (state, action) => {
      state.countryList = action.payload;
    },
    fetchDepartmentSuccess: (state, action) => {
      state.departments = action.payload;
    },
    fetchDesignationsSuccess: (state, action) => {
      state.designations = action.payload;
    },
    createOrUpdateOrgSuccess: (state, action) => {
      const { org } = action.payload
      if (state.userOrg && state.userOrg.id === org.id) {
        state.userOrg = org;
      } else {
        state.orgs = { ...state.orgs, [org.id]: org };
      }
    },
    createOrUpdateOrgStatus: (state, action) => {
      state.createOrgSuccess = action.payload.create;
      state.updateOrgSuccess = action.payload.updated;
    },
    fetchOrgByIdSuccess: (state, action) => {
      const { org, orgId } = action.payload
      if (org && !org.id) {
        state.orgs = { ...state.orgs, [orgId]: 'NotFound' };
      } else {
        if (state.userOrg && state.userOrg.id === org.id) {
          state.userOrg = { ...state.userOrg, ...org }
        } else {
          state.orgs = { ...state.orgs, [org.id]: { ...(state.orgs[org.id] || {}), ...org } };
        }
      }
    },
    fetchOrgSuccess: (state, action) => {
      state.orgList = action.payload;
    },
    fetchOrganisersSuccess: (state, action) => {
      state.organisers = { ...state.organisers, [action.payload.orgId]: action.payload.organisers }
    },
    updateOrganiserSuccess: (state, action) => {
      state.updateOrganiserSuccess = action.payload;
    },
    freqListSuccess: (state, action) => {
      state.notificationTypes = action.payload
    },
    setContinuerResponders: (state, action) => {
      state.continuerResponders = action.payload
    },
    setInvestigatorResponders: (state, action) => {
      state.investigatorResponders = action.payload
    },
  }
})
export const orgActions = orgSlice.actions;

ReducerRegistry.register("org", orgSlice.reducer)